
  
  .form-wrapper {
    display: flex;
    flex-direction: column;
    width: 280px;
    max-width: 80%;
    min-width: 100px;
    padding: 20px 40px;
    border-radius: 6px;
    box-shadow: 1px 3px 5px grey;
    background-color: #fefefe;
  }

  
  .form-wrapper > h2 {
    display: flex;
    justify-content: center;
    font-family: "Segoe UI", "Ubuntu", "Roboto", "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 2em;
    font-weight: lighter;
    margin-top: 0.25em;
    color: #222;
  }
  
  form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  
  .info {
    padding-bottom: 1em;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  
  label {
    margin-bottom: 0.5em;
    color: #444;
    font-weight: lighter;
  }
  
  input {
    padding: 10px 10px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #d6d1d5;
  }
  
  input::placeholder {
    font-size: 1.2em;
    font-weight: lighter;
    color: #bbb;
  }
  
  button {
    min-width: 100%;
    cursor: pointer;
    margin-right: 0.25em;
    margin-top: 0.5em;
    padding: 	0.938em;
    border: none;
    border-radius: 4px;
    background-color: #22223B;
    color: #fefefe;
  }
  button:hover {
    background-color: #4A4E69;
    color: #fefefe;
  }
  
  input.error {
    border: 1px solid #EADAE4;
  }
  
  .error {
    color:#db2269;
    font-size: 0.625em;

  }

  .fullName {
    margin-right: 1%;
  }
  
  .fullName,
  .email,
  .password {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  .fullName {
    width: 100%;
  }
  
  .email,
  .password {
    width: 100%;
  }
  
  .submit {
    width: 100%;
  }

  .Controls{
    padding-top: 24px;
    font-size: 30px;
    margin-bottom: 1em;
  }

  .Color{
    color: #ff0000;
  }

  
  .fill{
      object-fit:scale-down;
      object-position: 0 0;
  }  
