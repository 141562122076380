.cardForm {
    position:absolute;
    left: 50%;
    transform: translate(-50%);
    box-shadow: 1px 3px 5px grey;
    border-radius: 6px;
    padding: 1em;
    background-color: transparent;
}
@media print {
    .noPrint {
        display:none;
    }
    .printCardForm{
    }
}

/*
style={{
    position: 'absolute', left: '50%',
    transform: 'translate(-50%)',
    boxShadow: "1px 3px 5px grey",
    borderRadius: "6px",
    padding: '1em',
}}
 */