.body {
    background-color: black;
}

.divForm {
    background-color: white;
}

.card {
    box-shadow: 0px 8px 36px #222;
}
.backButton {
    color:black !important;
    background-color: white !important;
    border-color: black !important;
}
.backButton:hover {
    color:white !important;
    background-color: cornflowerblue !important;
    border-color: white !important;
}
.nextButton {
    color:black !important;
    background-color: white !important;
    border-color: black !important;
}
.nextButton:hover {
    color:white !important;
    background-color: forestgreen !important;
    border-color: white !important;
}

/*
.f1 {
    background-color: lightblue;
    #display: none;
}


.f2 {
    display: none;
    background-color: pink;

}

.f3 {
    display: none;
    background-color: lightgreen;

}

.f4 {
    display: none;
    background-color: lightsalmon;

}

.f5 {
    display: none;
    background-color: lightyellow;

}

.submitButton {
    #visibility: hidden;
}
*/