.card {
    background-color: white;
    box-shadow: 1px 3px 5px 1px grey;
    border-radius: 6px;
    padding: 1em;
    width: 600px;
}

@media only screen and (max-width: 456px) {
    .card {
        width: 300px;
    }
}

@media only screen and (min-width: 1440px) {
    .card {
        width: 1100px;
    }
}

.card:hover {
    background-color: white;
    box-shadow: 2px 4px 6px 2px darkgrey;
    border-radius: 7px;
    padding: 1em;
}

.visualizzaButton {
    color:black !important;
    background-color: white !important;
    border-color: black !important;
    border-radius: 1px;
    border: 1px;
}

.visualizzaButton:hover {
    color:white !important;
    background-color: cornflowerblue !important;
}