body{
   background-color: whitesmoke;
   align-items: center;
}   


.fill{
   object-fit:scale-down;
   object-position: 0 0;
}  

.falseLink {
   color: #1976d2;
   cursor: pointer;
   text-decoration: underline;  
}

.error {
   color:#db2269;
   font-size: 0.625em;

 }